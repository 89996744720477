// @todo transferir para o winx-base
import { $fetch, FetchOptions } from 'ofetch';
import { defu } from 'defu';

import { Ref, toValue } from 'vue';
import type { UseFetchOptions } from '#app';

import { useFetch, useHandlingError } from '#imports';

export function useFetchClient<T>(url: string | Ref<string> | (() => string), options: UseFetchOptions<T> = {}) {
  const defaults: UseFetchOptions<T> = {
    key: toValue(url),
    onResponseError({ response }) {
      useHandlingError(response);
    },
  };

  const params = defu(options, defaults);

  return useFetch(url, params);
}

export async function useClient(url: string, options: FetchOptions = {}) {
  try {
    return await $fetch(url, options);
  } catch (e) {
    return useHandlingError(e);
  }
}
